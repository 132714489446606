import { createStore } from 'easy-peasy';
import { Auth, authStore } from './states/auth';
import { Profile, profileStore } from './states/profile';

export interface StoreModel {
	auth: Auth;
	profile: Profile;
}

const storeModel: StoreModel = {
	auth: authStore,
	profile: profileStore,
}

export const store = createStore(storeModel);
